<template>
  <v-card
    class="mx-auto my-5"
    color="#26c6da"
    dark
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-robot
      </v-icon>
      <span class="text-h6 font-weight-light">AI Predict Result</span>
    </v-card-title>

    <v-card-text class="text-h5 font-weight-bold">
    Recommended Action:<br>
    <br>
     <div style="text-align:center"> "Non-invasive Ventilation."</div>
    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">


        <v-list-item-content>
          <v-list-item-title>Powered By ICU AI</v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-information-outline
          </v-icon>
          <span class="subheading mr-2">For reference only.</span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "AIPredict",
    data: () => ({
      //
    }),
  }
</script>