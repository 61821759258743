<template>

<v-card  class="mx-auto my-5" outlined>
        
        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            Treatment Log
          </div>

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in messages"
              :key="message.time"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> @{{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
</template>

<script>
  export default {
    data: () => ({
        name: "timeline",
      messages: [
        {
          from: 'Doctor Wang',
          message: `Low-flow oxygen intake`,
          time: '10:42am',
          color: 'deep-purple lighten-1',
        },
        {
          from: 'AI',
          message: 'Prediction: Begin non-invasive ventilation',
          time: '10:37am',
          color: 'green',
        },
        {
          from: 'Doctor Wang',
          message: 'Continuous monitoring, observation of vital indications, sedation, diuresis, hypotension, indwelling urinary catheter, recording of 24-hour intake and output',
          time: '9:47am',
          color: 'deep-purple lighten-1',
        },        
        {
          from: 'Doctor Wang',
          message: `Pregnancy 1 Delivery 1 39 weeks gestation ROA Cesarean section
Postpartum hemorrhage
severe eclampsia
Hemorrhagic shock
DIC`,
          time: 'yesterday',
          color: 'deep-purple lighten-1',
        },
      ],
    }),
  }
</script>