<template>
  <v-card
    class="mx-auto my-5"
    outlined
    style="
    padding-bottom: 10px;
"
  >
   <v-card-title>
    <h5>Statistics</h5>
    </v-card-title>
 <!-- <v-tabs
      color="deep-purple accent-4"
            show-arrows
      center-active
      style="margin-bottom:10px;"
    >-->
      
      



      <!-- <v-tab-item> -->
<div class="chart-container">

  <div class="page-line-chart" style="margin-top:20px;">
<h4>中心静脉压(CVP)</h4>
    
    <ve-line :data="chartData"></ve-line>
  </div>
     <!-- </v-tab-item> -->
      <!-- <v-tab-item> -->
        <div class="page-line-chart" style="margin-top:20px;">
<h4>舒张压(DBP)</h4>
    
    <ve-line :data="chartData2"></ve-line>
  </div>
      <!-- </v-tab-item> -->
            <!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
<h4>二氧化碳分压(PCO2)</h4>
    
    <ve-line :data="chartData3"></ve-line>
  </div>
      <!-- </v-tab-item> -->

      <!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>呼气末正压(PEEP)</h4>
    
    <ve-line :data="PEEP"></ve-line>
  </div>
      <!-- </v-tab-item> -->

      <!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
 <h4>平均肺动脉压(mPAP)</h4>
    
    <ve-line :data="mPAP"></ve-line>
  </div>
      <!-- </v-tab-item> -->

      <!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>肺动脉收缩压(PASP)</h4>
    
    <ve-line :data="PASP"></ve-line>
  </div>
      <!-- </v-tab-item> -->
<!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>肺毛细血管楔压(PCWP)</h4>
    
    <ve-line :data="PCWP"></ve-line>
  </div>
      <!-- </v-tab-item> -->
      <!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>呼吸频率(RESP)</h4>
    
    <ve-line :data="RESP"></ve-line>
  </div>
      <!-- </v-tab-item> -->

<!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
<h4>全身血管阻力(SVR)</h4>
    
    <ve-line :data="SVR"></ve-line>
  </div>
      <!-- </v-tab-item> -->


<!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>收缩压(SBP)</h4>
    
    <ve-line :data="SBP"></ve-line>
  </div>
      <!-- </v-tab-item> -->
<!-- <v-tab-item> -->
              <div class="page-line-chart" style="margin-top:20px;">
      <h4>静脉(pvo2)</h4>
    
    <ve-line :data="pvo2"></ve-line>
  </div>
      <!-- </v-tab-item> -->
</div>











    </v-tabs>
  
    </v-card>
</template>

<script>
export default {
    name: "linechart",
  data() {
    return {
      chartData: {
        columns: ["Time", "Lowest Normal Value (kPa)", "Patient's Value (kPa)", "Highest Normal Value (kPa)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (kPa)": 0.49 , "Patient's Value (kPa)": 0.66, "Highest Normal Value (kPa)": 1.18 },
          { Time: "20:10", "Lowest Normal Value (kPa)": 0.49, "Patient's Value (kPa)": 0.51, "Highest Normal Value (kPa)": 1.18 },
          { Time: "20:20", "Lowest Normal Value (kPa)": 0.49, "Patient's Value (kPa)": 0.96, "Highest Normal Value (kPa)": 1.18 },
          { Time: "20:30", "Lowest Normal Value (kPa)": 0.49, "Patient's Value (kPa)": 0.70, "Highest Normal Value (kPa)": 1.18 },
          { Time: "20:40", "Lowest Normal Value (kPa)": 0.49, "Patient's Value (kPa)": 1.17, "Highest Normal Value (kPa)": 1.18 },
          { Time: "20:50", "Lowest Normal Value (kPa)": 0.49, "Patient's Value (kPa)": 0.99, "Highest Normal Value (kPa)": 1.18 }
        ]
      },
      chartData2: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 60 , "Patient's Value (mmHg)": 72, "Highest Normal Value (mmHg)": 89},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 60, "Patient's Value (mmHg)": 69, "Highest Normal Value (mmHg)": 89 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 60, "Patient's Value (mmHg)": 77, "Highest Normal Value (mmHg)": 89 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 60, "Patient's Value (mmHg)": 80, "Highest Normal Value (mmHg)": 89 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 60, "Patient's Value (mmHg)": 67, "Highest Normal Value (mmHg)": 89 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 60, "Patient's Value (mmHg)": 85, "Highest Normal Value (mmHg)": 89 }
        ]
      },
      chartData3: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 35 , "Patient's Value (mmHg)": 36, "Highest Normal Value (mmHg)": 45},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 35, "Patient's Value (mmHg)": 47, "Highest Normal Value (mmHg)": 45 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 35, "Patient's Value (mmHg)": 43, "Highest Normal Value (mmHg)": 45 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 35, "Patient's Value (mmHg)": 41, "Highest Normal Value (mmHg)": 45 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 35, "Patient's Value (mmHg)": 39, "Highest Normal Value (mmHg)": 45 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 35, "Patient's Value (mmHg)": 41, "Highest Normal Value (mmHg)": 45 }
        ]
      },
      PEEP: {
        columns: ["Time", "Lowest Normal Value (cmH2O)", "Patient's Value (cmH2O)", "Highest Normal Value (cmH2O)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (cmH2O)": 3 , "Patient's Value (cmH2O)": 5.1, "Highest Normal Value (cmH2O)": 6},
          { Time: "20:10", "Lowest Normal Value (cmH2O)": 3, "Patient's Value (cmH2O)": 5.0, "Highest Normal Value (cmH2O)": 6 },
          { Time: "20:20", "Lowest Normal Value (cmH2O)": 3, "Patient's Value (cmH2O)": 5.1, "Highest Normal Value (cmH2O)": 6 },
          { Time: "20:30", "Lowest Normal Value (cmH2O)": 3, "Patient's Value (cmH2O)": 5.2, "Highest Normal Value (cmH2O)": 6 },
          { Time: "20:40", "Lowest Normal Value (cmH2O)": 3, "Patient's Value (cmH2O)": 5.0, "Highest Normal Value (cmH2O)": 6 },
          { Time: "20:50", "Lowest Normal Value (cmH2O)": 3, "Patient's Value (cmH2O)": 5.1, "Highest Normal Value (cmH2O)": 6 }
        ]
      },
      mPAP: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 10 , "Patient's Value (mmHg)": 16},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 17 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 13},
          { Time: "20:30", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 11 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 19 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 11 }
        ]
      },
      PASP: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 10 , "Patient's Value (mmHg)": 36, "Highest Normal Value (mmHg)": 30},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 27, "Highest Normal Value (mmHg)": 30 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 23, "Highest Normal Value (mmHg)": 30 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 21, "Highest Normal Value (mmHg)": 30 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 29, "Highest Normal Value (mmHg)": 30 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 10, "Patient's Value (mmHg)": 21, "Highest Normal Value (mmHg)": 30 }
        ]
      },
      PCWP: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 6 , "Patient's Value (mmHg)": 6, "Highest Normal Value (mmHg)": 12},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 6, "Patient's Value (mmHg)": 7, "Highest Normal Value (mmHg)": 12 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 6, "Patient's Value (mmHg)": 10, "Highest Normal Value (mmHg)": 12 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 6, "Patient's Value (mmHg)": 11, "Highest Normal Value (mmHg)": 12 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 6, "Patient's Value (mmHg)": 9, "Highest Normal Value (mmHg)": 12 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 6, "Patient's Value (mmHg)": 7, "Highest Normal Value (mmHg)": 12 }
        ]
      },
      RESP: {
        columns: ["Time", "Lowest Normal Value (Times/min)", "Patient's Value (Times/min)", "Highest Normal Value (Times/min)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (Times/min)": 16 , "Patient's Value (Times/min)": 16, "Highest Normal Value (Times/min)": 18},
          { Time: "20:10", "Lowest Normal Value (Times/min)": 16, "Patient's Value (Times/min)": 17, "Highest Normal Value (Times/min)": 18 },
          { Time: "20:20", "Lowest Normal Value (Times/min)": 16, "Patient's Value (Times/min)": 17, "Highest Normal Value (Times/min)": 18 },
          { Time: "20:30", "Lowest Normal Value (Times/min)": 16, "Patient's Value (Times/min)": 16, "Highest Normal Value (Times/min)": 18 },
          { Time: "20:40", "Lowest Normal Value (Times/min)": 16, "Patient's Value (Times/min)": 18, "Highest Normal Value (Times/min)": 18 },
          { Time: "20:50", "Lowest Normal Value (Times/min)": 16, "Patient's Value (Times/min)": 17, "Highest Normal Value (Times/min)": 18 }
        ]
      },
      SVR: {
        columns: ["Time", "Lowest Normal Value (BSA)", "Patient's Value (BSA)", "Highest Normal Value (BSA)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (BSA)": 1491 , "Patient's Value (BSA)": 1567, "Highest Normal Value (BSA)": 2486},
          { Time: "20:10", "Lowest Normal Value (BSA)": 1491, "Patient's Value (BSA)": 1862, "Highest Normal Value (BSA)": 2486 },
          { Time: "20:20", "Lowest Normal Value (BSA)": 1491, "Patient's Value (BSA)": 1777, "Highest Normal Value (BSA)": 2486 },
          { Time: "20:30", "Lowest Normal Value (BSA)": 1491, "Patient's Value (BSA)": 1597, "Highest Normal Value (BSA)": 2486 },
          { Time: "20:40", "Lowest Normal Value (BSA)": 1491, "Patient's Value (BSA)": 2337, "Highest Normal Value (BSA)": 2486 },
          { Time: "20:50", "Lowest Normal Value (BSA)": 1491, "Patient's Value (BSA)": 2111, "Highest Normal Value (BSA)": 2486 }
        ]
      },
      SBP: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 100 , "Patient's Value (mmHg)": 116, "Highest Normal Value (mmHg)": 120},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 100, "Patient's Value (mmHg)": 117, "Highest Normal Value (mmHg)": 120 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 100, "Patient's Value (mmHg)": 113, "Highest Normal Value (mmHg)": 120 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 100, "Patient's Value (mmHg)": 111, "Highest Normal Value (mmHg)": 120 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 100, "Patient's Value (mmHg)": 119, "Highest Normal Value (mmHg)": 120 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 100, "Patient's Value (mmHg)": 111, "Highest Normal Value (mmHg)": 120 }
        ]
      },
      pvo2: {
        columns: ["Time", "Lowest Normal Value (mmHg)", "Patient's Value (mmHg)", "Highest Normal Value (mmHg)"],
        rows: [
          { Time: "20:00", "Lowest Normal Value (mmHg)": 37 , "Patient's Value (mmHg)": 36, "Highest Normal Value (mmHg)": 40},
          { Time: "20:10", "Lowest Normal Value (mmHg)": 37, "Patient's Value (mmHg)": 37, "Highest Normal Value (mmHg)": 40 },
          { Time: "20:20", "Lowest Normal Value (mmHg)": 37, "Patient's Value (mmHg)": 43, "Highest Normal Value (mmHg)": 40 },
          { Time: "20:30", "Lowest Normal Value (mmHg)": 37, "Patient's Value (mmHg)": 40, "Highest Normal Value (mmHg)": 40 },
          { Time: "20:40", "Lowest Normal Value (mmHg)": 37, "Patient's Value (mmHg)": 39, "Highest Normal Value (mmHg)": 40 },
          { Time: "20:50", "Lowest Normal Value (mmHg)": 37, "Patient's Value (mmHg)": 38, "Highest Normal Value (mmHg)": 40 }
        ]
      }
    };
  }
};
</script>
<style>
.page-line-chart{
width:32%;
border: thin solid rgba(0, 0, 0, 0.12);
margin-top:10px !important;
border-radius: 10px;
}
.page-line-chart h4{
margin-left: 10px;
}
.chart-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
</style>

