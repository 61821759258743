<template>
 <v-container style="max-width:95%">
 <v-card mx-auto>
    <v-toolbar flat>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>

    </v-toolbar>
    <v-banner
    >
    <v-row no-gutters>
      <v-col cols="10">
        <h3>Good Morning! Doctor Wang.</h3>
        <div class="text-overline mb-4">
          Super Hospital - OICU - Obstetrics and Gynecology Department - Attending Physician
        </div>
      </v-col>
      <v-col>
              <v-icon>mdi-bunk-bed-outline</v-icon>
              Total Patients: 3

      </v-col>
      </v-row>
      <v-row no-gutters>


  <v-tabs style="margin-top:10px">
    <v-tab>Patient One: Xiao Hong</v-tab>
    <v-tab>Patient Two: Xiao Ming</v-tab>
    <v-tab>Patient Three: Zhang San</v-tab>
  </v-tabs>
    </v-row>
      


    </v-banner>
    <v-card-text class="grey lighten-4">
    <v-row no-gutters>
    <v-col col="6"><infocard style="
    max-width: 97%;
"/>
<timeline style="
    max-width: 97%;
"/>

    </v-col>
 
    <v-col col="6" >
    <aipredict style="
    max-width: 97%;
"/>
        <heartrate style="
    max-width: 97%;
"/></v-col>
    </v-row>

      <linechart />
  
  
    </v-card-text>
  </v-card>
  
</v-container>
</template>

<script>
  import infocard from '../components/infocard'
  import heartrate from '../components/heartrate'
  import linechart from '../components/linechart'
  import timeline from '../components/Timeline'
  import aipredict from '../components/AIPredict'

  export default {
    name: 'Home',

    components: {
      infocard,
      heartrate,
      linechart,
      timeline,
      aipredict
      
    },
  }
</script>
